import {
  SbBlokData,
  StoryblokComponent,
  storyblokEditable,
} from '@storyblok/react';
import cx from 'classnames';

import { TitleBlokProps } from '../Title/Title';
import PageContainer from '../../components/PageContainer/PageContainer';
import { CtaButtonBlokProps } from '../CtaButton/CtaButton';
import { CtaLinkBlokProps } from '../CtaLink/CtaLink';
import FaqSectionGrid from './FaqSectionGrid';
import FaqSectionAccordion from './FaqSectionAccordion';
import { FaqSectionItemBlokProps } from '../FaqSectionItem/types';

export interface FaqSectionBlokProps extends SbBlokData {
  backgroundColor?: 'GSparchment/200' | 'GSbase/white' | '';
  contentWidth?: 'narrow' | 'wide';
  cta?: Array<CtaButtonBlokProps | CtaLinkBlokProps>;
  id?: string;
  items: Array<FaqSectionItemBlokProps>;
  layout?: 'oneColumn' | 'twoColumns' | '';
  title: Array<TitleBlokProps>;
  topPadding?: 'default' | 'none' | '';
  type?: 'accordion' | 'grid' | '';
  visibility?: 'enabled' | 'disabled' | '';
  visibilityOnMobile?: 'enabled' | 'disabled' | '';
}

export interface FaqProps {
  blok: FaqSectionBlokProps;
}

const FaqSection = ({ blok }: FaqProps) => {
  const {
    backgroundColor,
    cta = [],
    id,
    items,
    topPadding = 'default',
    type = 'grid',
    visibility = 'enabled',
    visibilityOnMobile = 'enabled',
  } = blok;

  const filteredItems = items.filter((item) => item.visibility !== 'disabled');

  return (
    <section
      {...storyblokEditable(blok)}
      className={cx(
        'w-full text-GSdeep/900 scroll-mt-16 sm:scroll-mt-20 lg:scroll-mt-36',
        {
          'bg-GSbase/white':
            !backgroundColor || backgroundColor === 'GSbase/white',
          'bg-GSparchment/200': backgroundColor === 'GSparchment/200',
          'hidden sm:block':
            (visibility === 'enabled' || visibility === '') &&
            visibilityOnMobile === 'disabled',
          hidden: visibility === 'disabled',
        },
      )}
      id={id}
    >
      <PageContainer className={cx({ '!pt-0': topPadding === 'none' })}>
        {type === 'accordion' ? (
          <FaqSectionAccordion blok={{ ...blok, items: filteredItems }} />
        ) : (
          <FaqSectionGrid blok={{ ...blok, items: filteredItems }} />
        )}
      </PageContainer>
      {cta && cta.length > 0 && (
        <div className="w-full flex flex-col sm:flex-row gap-2 sm:gap-8 justify-center">
          {cta.map((nestedBlok) => (
            <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
          ))}
        </div>
      )}
    </section>
  );
};

export default FaqSection;
