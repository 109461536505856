import { MouseEvent } from 'react';
import cx from 'classnames';

import { ChevronDownIcon } from '../../icons/ChevronDown';

interface FaqItemProps {
  body: string;
  id: string | undefined;
  isExpanded?: boolean;
  setExpanded?: (id: string | undefined) => void;
  title: string;
}

const FaqItem = ({
  body,
  id,
  isExpanded,
  setExpanded,
  title,
}: FaqItemProps) => {
  const toggleItem = () => setExpanded?.(isExpanded ? '' : id);
  const preventTextSelection = (event: MouseEvent) => {
    if (event.detail > 1) {
      event.preventDefault();
    }
  };

  if (title === '') {
    return null;
  }

  return (
    <div
      className={cx(
        'self-start',
        'w-full flex flex-col text-GSbase/black border-GSblue/100 border p-4 sm:p-6 lg:p-8 shadow-lg rounded hover:cursor-pointer',
        {
          'bg-GSbase/white': !isExpanded,
          'bg-GSblue/50': isExpanded,
        },
      )}
      onClick={toggleItem}
      onMouseDown={preventTextSelection}
    >
      <div
        className={cx(
          'font-raleway font-bold text-base sm:text-lg lg:text-xl cursor-pointer w-full flex gap-4 items-center',
          {
            'text-GSdeep/900 duration-[200ms]': !isExpanded,
            'text-GSblue/600 mb-4 lg:mb-6 duration-[200ms]': isExpanded,
          },
        )}
      >
        <span>{title}</span>
        <span className="ml-auto">
          <ChevronDownIcon className={cx({ 'rotate-180': isExpanded })} />
        </span>
      </div>
      <div
        className={cx(
          'text-GSdeep/900 font-normal text-sm lg:text-base break-words whitespace-pre-wrap flex flex-col gap-4',
          {
            'hidden duration-[200ms]': !isExpanded,
            'block duration-[200ms]': isExpanded,
            'select-none': !isExpanded,
          },
        )}
      >
        {body}
      </div>
    </div>
  );
};

export default FaqItem;
