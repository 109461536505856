import StoryblokImage from '@/components/StoryblokImage/StoryblokImage';
import { SbBlokData, storyblokEditable } from '@storyblok/react';

export interface StatisticItemProps {
  blok: StatisticItemBlokProps;
}

export interface StatisticItemBlokProps extends SbBlokData {
  title: string;
  description: string;
  icon: Asset;
}

const StatisticItem = ({ blok }: StatisticItemProps) => {
  const { description, icon, title } = blok;

  return (
    <div {...storyblokEditable(blok)} className="flex flex-col sm:gap-2">
      {icon && (
        <StoryblokImage
          blok={blok.icon}
          className="self-center max-h-12 max-w-[48px] sm:max-h-14 sm:max-w-[56px] lg:max-h-16 lg:max-w-[64px]"
        />
      )}
      {title && (
        <div
          className="
          font-raleway text-GSblue/500 font-extrabold text-[28px] leading-10 [overflow-wrap:anywhere]
          sm:text-[32px] sm:leading-[48px]
          lg:text-[40px] lg:leading-[56px]"
        >
          {title}
        </div>
      )}
      {description && (
        <div className="text-GSdeep/500 text-center font-medium text-sm sm:text-base [overflow-wrap:anywhere]">
          {description}
        </div>
      )}
    </div>
  );
};

export default StatisticItem;
