import cx from 'classnames';
import { FieldInputProps } from 'formik';

export interface TextInputProps extends FieldInputProps<string | number> {
  hubspotId?: string;
  type: string;
  placeholder?: string;
  error: string;
  isTutorForm?: boolean;
  label?: string;
  name: string;
  disabled?: boolean;
}

const defaultClasses =
  'bg-GSbase/white text-sm font-normal py-3.5 px-2 border-2 border-GSsteel/300 rounded text-GSdeep/900 placeholder:text-GSsteel/500';
const hoverClasses = 'hover:border-GSblue/500';
const focusClasses =
  'focus:ring-GSblue/100 focus:ring-offset-0 focus:ring-4 focus:border-GSblue/500 focus:outline-none';
const disabledClasses =
  'disabled:bg-GSsteel/50 disabled:border-GSsteel/100 disabled:text-GSsteel/900 disabled:text-opacity-40';

const TextInput = (field: TextInputProps) => {
  const { disabled, error, label, name, value } = field;
  const { hubspotId, isTutorForm, ...fieldRest } = field;

  return (
    <div className="w-full flex flex-col gap-y-2">
      {hubspotId && (
        <>
          <label className="hidden" htmlFor={hubspotId}>
            {hubspotId}
          </label>
          <input className="hidden" value={value} name={hubspotId} readOnly />
        </>
      )}
      <div className="w-full flex flex-col gap-y-1">
        {label && (
          <label
            className={cx('font-medium text-sm text-GSdeep/900', {
              'lg:text-base': isTutorForm,
            })}
            htmlFor={name}
          >
            {label}
          </label>
        )}
        <input
          className={cx(
            defaultClasses,
            hoverClasses,
            focusClasses,
            disabledClasses,
            {
              '!border-GSfunctional/error': error && error !== '',
              'placeholder:!text-GSsteel/500/[.2]': disabled,
              'lg:text-base': isTutorForm,
            },
          )}
          {...fieldRest}
          id={name}
          disabled={disabled}
        />
      </div>
      {error && (
        <div className="text-xs text-GSfunctional/error font-medium">
          {error}
        </div>
      )}
    </div>
  );
};

export default TextInput;
