import { useState } from 'react';
import { SbBlokData, storyblokEditable } from '@storyblok/react';
import cx from 'classnames';

import TitleBlok, { TitleBlokProps } from '../Title/Title';
import { FaqSectionItemBlokProps } from '../FaqSectionItem/types';
import FaqItem from '../../components/FaqItem/FaqItem';
import { renderItemBody } from './utils';

export interface FaqSectionAccordionBlokProps extends SbBlokData {
  contentWidth?: 'narrow' | 'wide' | '';
  items: Array<FaqSectionItemBlokProps>;
  layout?: 'oneColumn' | 'twoColumns' | '';
  title: Array<TitleBlokProps>;
}

export interface FaqSectionAccordionProps {
  blok: FaqSectionAccordionBlokProps;
}

const FaqSectionAccordion = ({ blok }: FaqSectionAccordionProps) => {
  const [expandedItemId, setExpandedItemId] = useState<string | undefined>();

  const {
    contentWidth = 'wide',
    items,
    layout = 'oneColumn',
    title: [title],
  } = blok;

  return (
    <div className="w-full grid grid-cols-6 sm:grid-cols-12 gap-x-2 gap-y-8 sm:gap-x-4 sm:gap-y-6 lg:gap-8">
      <div
        className={cx('grid col-span-6 sm:col-span-12', {
          'lg:col-start-2 lg:col-span-10':
            contentWidth === 'wide' || contentWidth === '',
          'sm:col-start-2 sm:col-span-10 lg:col-start-3 lg:col-span-8':
            contentWidth === 'narrow',
        })}
      >
        <TitleBlok blok={title} />
      </div>
      <div
        className={cx('w-full grid col-span-6 sm:col-span-12 gap-2 sm:gap-4', {
          'lg:col-start-2 lg:col-span-10':
            contentWidth === 'wide' || contentWidth === '',
          'sm:col-start-2 sm:col-span-10 lg:col-start-3 lg:col-span-8':
            contentWidth === 'narrow',
        })}
      >
        <div
          className={cx('w-full flex flex-col gap-y-2 sm:gap-y-4 sm:gap-x-8', {
            'sm:grid sm:grid-cols-2': layout === 'twoColumns',
          })}
        >
          <div className="w-full flex flex-col gap-y-2 sm:gap-y-4">
            {items
              ?.slice(0, Math.ceil(items.length / 2))
              .map((item: FaqSectionItemBlokProps) => (
                <div key={item._uid} {...storyblokEditable(item)}>
                  <FaqItem
                    body={renderItemBody(item)}
                    id={item._uid}
                    isExpanded={expandedItemId === item._uid}
                    setExpanded={setExpandedItemId}
                    title={item.title}
                  />
                </div>
              ))}
          </div>
          <div className="w-full flex flex-col gap-y-2 sm:gap-y-4">
            {items
              ?.slice(Math.ceil(items.length / 2))
              .map((item: FaqSectionItemBlokProps) => (
                <div key={item._uid} {...storyblokEditable(item)}>
                  <FaqItem
                    body={renderItemBody(item)}
                    id={item._uid}
                    isExpanded={expandedItemId === item._uid}
                    setExpanded={setExpandedItemId}
                    title={item.title}
                  />
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FaqSectionAccordion;
