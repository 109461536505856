import { ComponentType } from 'react';
import cx from 'classnames';

export interface TitleProps {
  alignment?: 'left' | 'center' | 'right' | '';
  header: string;
  headingLevel?: ComponentType | keyof JSX.IntrinsicElements;
  subheader?: string;
  layout?: 'default' | 'full' | '';
  children?: React.ReactNode;
}

const Title = ({
  alignment = 'center',
  children,
  headingLevel,
  header,
  subheader,
  layout = 'default',
}: TitleProps) => {
  const Tag = headingLevel || 'h2';

  return (
    <div className="w-full">
      <div
        className={cx('flex flex-col gap-y-2', {
          'items-center': alignment === '' || alignment === 'center',
          'items-start': alignment === 'left',
          'items-end': alignment === 'right',
        })}
      >
        {header && (
          <Tag
            className={cx('font-raleway text-GSblue/600', {
              'font-bold text-xl sm:text-2xl lg:text-[28px] lg:leading-[40px]':
                layout === 'default' || layout === '',
              'font-extrabold text-[28px] leading-10 sm:text-[32px] sm:leading-[48px] lg:text-[40px] lg:leading-[56px]':
                layout === 'full',
              'text-center': alignment === '' || alignment === 'center',
              'text-left': alignment === 'left',
              'text-right': alignment === 'right',
            })}
          >
            {header}
          </Tag>
        )}
        {subheader && (
          <div
            className="font-worksans text-GSdeep/900 font-normal text-sm text-center
            sm:text-base
            lg:text-lg"
          >
            {subheader}
          </div>
        )}
        {children}
      </div>
    </div>
  );
};

export default Title;
