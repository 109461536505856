import { useEffect, useState } from 'react';
import Script from 'next/script';
import cx from 'classnames';
import {
  SbBlokData,
  StoryblokComponent,
  storyblokEditable,
} from '@storyblok/react';
import { CtaLinkBlokProps } from '@/blocks/CtaLink/CtaLink';
import TitleBlok, { TitleBlokProps } from '@/blocks/Title/Title';
import { Carousel } from '@/components';
import PageContainer from '@/components/PageContainer/PageContainer';
import StoryblokImage from '@/components/StoryblokImage/StoryblokImage';
import {
  LayoutTypes,
  fallbackLink,
  languageTagToLanguage,
  layoutToHeight,
  layoutToTemplateId,
} from '@/components/TrustpilotWidget/utils';
import { CtaButtonBlokProps } from '../CtaButton/CtaButton';
import { TrustpilotFallbackReviewBlokProps } from '../TrustpilotFallbackReview/TrustpilotFallbackReview';

export interface TrustpilotWidgetBlokProps extends SbBlokData {
  ctaLink: Array<CtaButtonBlokProps | CtaLinkBlokProps>;
  id?: string;
  layout: LayoutTypes;
  fallbackImage: Asset;
  fallbackReviews?: Array<TrustpilotFallbackReviewBlokProps>;
  showFallbackReviews: boolean;
  language: string;
  title: Array<TitleBlokProps>;
  visibility?: 'enabled' | 'disabled';
  visibilityOnMobile?: 'enabled' | 'disabled';
}

interface TrustpilotWidgetProps {
  blok: TrustpilotWidgetBlokProps;
}

const TrustpilotWidget = ({ blok }: TrustpilotWidgetProps) => {
  const [isMobile, setIsMobile] = useState(false);
  const {
    ctaLink = [],
    id,
    title: [title] = [],
    language,
    layout,
    fallbackImage,
    fallbackReviews = [],
    showFallbackReviews = false,
    visibility,
    visibilityOnMobile,
  } = blok;

  if (visibility === 'disabled' && visibilityOnMobile !== 'enabled') {
    return null;
  }

  const contentStyles = () =>
    cx({
      'col-span-full col-start-1 sm:col-span-10 sm:col-start-2':
        layout === LayoutTypes.Carousel,
      'col-span-full col-start-1 lg:col-span-10 lg:col-start-2':
        layout === LayoutTypes.Grid,
      'col-span-full col-start-1':
        layout !== LayoutTypes.Carousel && layout !== LayoutTypes.Grid,
    });

  useEffect(() => {
    const mediaQuery = window.matchMedia('(max-width: 675px)');
    setIsMobile(mediaQuery.matches);
  });

  return (
    <>
      <Script
        type="text/javascript"
        src="//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js"
        strategy="lazyOnload"
      />
      <section
        {...storyblokEditable(blok)}
        className={cx('bg-GSbase/white', {
          'hidden sm:block':
            visibility === 'enabled' && visibilityOnMobile === 'disabled',
          hidden: visibility === 'disabled',
        })}
        id={id}
      >
        <PageContainer
          className={cx('', {
            'px-0 sm:px-4': showFallbackReviews,
          })}
        >
          {showFallbackReviews && fallbackReviews.length ? (
            <Carousel items={fallbackReviews} />
          ) : (
            <div className="w-full grid grid-cols-6 sm:grid-cols-12 gap-x-2 gap-y-4 sm:gap-x-4 sm:gap-y-6 lg:gap-x-8 lg:gap-y-10">
              {title && (
                <div className={contentStyles()}>
                  <TitleBlok blok={title} headingLevel="h2" />
                </div>
              )}
              <div
                className={cx('trustpilot-widget w-full', contentStyles())}
                data-locale={language}
                data-template-id={layoutToTemplateId(layout)}
                data-businessunit-id="5b9bd0cf3738d6000130250d"
                data-style-height={layoutToHeight(layout, isMobile)}
                data-style-width="100%"
                data-theme="light"
                data-stars="4,5"
                data-review-languages={languageTagToLanguage(language)}
              >
                <div className="flex justify-center bg-white text-black w-full">
                  <a
                    href={fallbackLink(language)}
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    {fallbackImage.filename ? (
                      <StoryblokImage
                        blok={fallbackImage}
                        fallbackAlt="Trustpilot"
                      />
                    ) : (
                      <span>Trustpilot</span>
                    )}
                  </a>
                </div>
              </div>
              {ctaLink && ctaLink.length > 0 && (
                <div className={contentStyles()}>
                  <div className="w-full flex flex-col sm:flex-row gap-2 sm:gap-8 justify-center">
                    {ctaLink.map((nestedBlok) => (
                      <StoryblokComponent
                        blok={nestedBlok}
                        key={nestedBlok._uid}
                      />
                    ))}
                  </div>
                </div>
              )}
            </div>
          )}
        </PageContainer>
      </section>
    </>
  );
};

export default TrustpilotWidget;
