import React from 'react';
import {
  SbBlokData,
  StoryblokComponent,
  storyblokEditable,
} from '@storyblok/react';
import cx from 'classnames';
import { Field, FieldProps, FormikProps, FormikValues } from 'formik';
import { getCookie } from 'cookies-next';

import { GtmIdContext, ReferringFallback } from '@/helpers/contexts';
import { FormTextDataStructure } from '@/helpers/datasources/fetchDatasources';
import { getFieldData } from '@/helpers/form/formFields';
import { addInternalParamsToUrl, fillUrlValues } from '@/helpers/urlParams';
import FormSubmitButton, {
  FormSubmitButtonBlokProps,
} from '../FormSubmitButton/FormSubmitButton';
import CtaButton, { CtaButtonBlokProps } from '../CtaButton/CtaButton';
import Message, { MessageBlokProps } from '../Message/Message';
import FormFieldsRow from '../FormFieldsRow/FormFieldsRow';

export interface FormStepBlokProps extends SbBlokData {
  description?: string;
  fields?: Array<SbBlokData>;
  fieldAndButtonLayout?: 'default' | 'inline';
  stepMessage?: Array<MessageBlokProps>;
  nextStepButton?: Array<FormSubmitButtonBlokProps>;
  redirectButton?: Array<CtaButtonBlokProps>;
}

interface FormStepProps {
  activeStep: number;
  blok: FormStepBlokProps;
  className?: string;
  formCountry?: string;
  formikProps: FormikProps<FormikValues>;
  isLastStep: boolean;
  labels?: FormTextDataStructure;
  isTutorForm?: boolean;
}

const FormStep = ({
  blok,
  className,
  formCountry,
  formikProps,
  isLastStep,
  labels = {},
  isTutorForm = false,
}: FormStepProps) => {
  const referringFallback = React.useContext(ReferringFallback);
  const gtmId = React.useContext(GtmIdContext);

  const {
    description,
    fields = [],
    fieldAndButtonLayout = 'default',
    stepMessage: [stepMessage] = [],
    nextStepButton: [nextStepButton] = [],
    redirectButton: [redirectButton] = [],
  } = { ...blok, fieldAndButtonLayout: blok.fieldAndButtonLayout || undefined };

  const filteredFields = fields.filter((field) => !field.excluded);

  const handleNextStep: React.MouseEventHandler<HTMLButtonElement> = () =>
    formikProps.submitForm();

  const handleRedirect: React.MouseEventHandler<
    HTMLButtonElement
  > = async () => {
    const errors = await formikProps.validateForm();

    if (Object.keys(errors).length === 0) {
      window.location.href = addInternalParamsToUrl(
        fillUrlValues(redirectButton.link.url, formikProps.values),
        {
          formikValues: formikProps.values,
          gtmId,
          referring: getCookie('referring') || referringFallback?.toString(),
        },
      );
    }
  };

  const renderField = (fieldBlok: SbBlokData) => (
    <div className="grow" key={fieldBlok._uid} hidden={!!fieldBlok.hidden}>
      <Field
        name={getFieldData(fieldBlok.component).id}
        disabled={formikProps.isSubmitting}
      >
        {(props: FieldProps) =>
          fieldBlok.hidden ? (
            <>
              <label
                className="hidden"
                htmlFor={getFieldData(fieldBlok.component).id}
              >
                {getFieldData(fieldBlok.component).hubspotId}
              </label>
              <Field name={getFieldData(fieldBlok.component).id}>
                {({ field }: FieldProps) => (
                  <input
                    {...field}
                    className="hidden"
                    id={getFieldData(fieldBlok.component).id}
                  />
                )}
              </Field>
            </>
          ) : (
            <StoryblokComponent
              blok={{
                ...fieldBlok,
                formCountry,
                formikErrors: formikProps.errors,
                labels,
                label:
                  fieldBlok.label || labels[`${fieldBlok.component}_label`],
                placeholder:
                  fieldBlok.placeholder ||
                  labels[`${fieldBlok.component}_placeholder`],
                touched: formikProps.touched,
                formikProps,
              }}
              {...props}
              isTutorForm={isTutorForm}
            />
          )
        }
      </Field>
    </div>
  );

  return (
    <div
      {...storyblokEditable(blok)}
      className={cx('flex flex-col gap-y-6 lg:gap-y-12', className)}
    >
      {description && (
        <div className="text-sm lg:text-base font-medium">{description}</div>
      )}
      <div className="flex flex-col gap-y-2">
        <div
          className={cx('flex gap-y-2 w-full', {
            'flex-col': fieldAndButtonLayout === 'default',
            'flex-col sm:flex-row sm:items-stretch':
              fieldAndButtonLayout === 'inline',
            'gap-y-4 lg:gap-y-8': isTutorForm,
          })}
        >
          {filteredFields.map((nestedBlok: SbBlokData) =>
            nestedBlok.component === 'formFieldsRow' ? (
              <FormFieldsRow
                key={nestedBlok._uid}
                blok={nestedBlok}
                fieldRenderer={renderField}
              />
            ) : (
              renderField(nestedBlok)
            ),
          )}

          {(nextStepButton || redirectButton) && (
            <div
              className={cx({
                'sm:max-w-[60%]': fieldAndButtonLayout === 'inline',
                'pt-2': fieldAndButtonLayout !== 'inline',
              })}
            >
              {nextStepButton && (
                <FormSubmitButton
                  key={isLastStep ? 'submit' : ''}
                  blok={nextStepButton}
                  isSubmit={isLastStep}
                  onClick={isLastStep ? undefined : handleNextStep}
                  disabled={formikProps.isSubmitting}
                  loading={formikProps.isSubmitting}
                />
              )}
              {redirectButton && (
                <CtaButton
                  key="redirect"
                  blok={redirectButton}
                  size="btn-MD"
                  onClick={handleRedirect}
                />
              )}
            </div>
          )}
        </div>

        {stepMessage && (
          <div className="w-11/12 sm:w-10/12 lg:w-9/12 self-center">
            <Message blok={stepMessage} />
          </div>
        )}
      </div>
    </div>
  );
};

export default FormStep;
