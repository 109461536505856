import React from 'react';
import Script from 'next/script';
import cx from 'classnames';
import useResponsive from '@/helpers/layout/useResponsive';
import StoryblokImage from '../StoryblokImage/StoryblokImage';
import {
  fallbackLink,
  languageTagToLanguage,
  layoutToHeight,
  layoutToTemplateId,
  LayoutTypes,
} from './utils';

export interface TrustpilotWidgetProps {
  layout: LayoutTypes;
  fallbackImage?: Asset;
  language: string;
  width?: number;
  height?: number;
}

const TrustpilotWidget = (props: TrustpilotWidgetProps) => {
  const { isMobile } = useResponsive();
  const { height, language, layout, fallbackImage, width } = props;

  return (
    <>
      <Script
        type="text/javascript"
        src="//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js"
        strategy="lazyOnload"
      />
      <div
        className={cx('trustpilot-widget')}
        data-locale={language}
        data-template-id={layoutToTemplateId(layout)}
        data-businessunit-id="5b9bd0cf3738d6000130250d"
        data-style-height={`${height}px` || layoutToHeight(layout, isMobile)}
        data-style-width={`${width}px` || '100%'}
        data-theme="light"
        data-stars="4,5"
        data-review-languages={languageTagToLanguage(language)}
      >
        <div className="flex justify-center text-black w-full">
          <a
            href={fallbackLink(language)}
            target="_blank"
            rel="noreferrer noopener"
          >
            {fallbackImage?.filename ? (
              <StoryblokImage blok={fallbackImage} fallbackAlt="Trustpilot" />
            ) : (
              <span>Trustpilot</span>
            )}
          </a>
        </div>
      </div>
    </>
  );
};

export default TrustpilotWidget;
